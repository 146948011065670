
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CooperativeSocietyTable from "@/components/admin/CooperativeSocietyTable.vue";
import AddCooperativeSociety from "@/components/modals/AddCooperativeSociety.vue";

export default defineComponent({
  name: "admin-cooperative-societies",
  components: {
    CooperativeSocietyTable,
    AddCooperativeSociety
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Cooperative Society", ["Users"]);
    });
  },
  created() {
    //Set page title
    document.title = "Cooperative Society | " + process.env.VUE_APP_TITLE;
  }
});
