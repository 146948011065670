
import { defineComponent, onMounted } from "vue";

import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import store from "@/store";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "admin-cooperative-society-table",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      cooperatives: [
        {
          cooperativeAdministrator: {
            emailAddress: "",
            fullName: "",
            policyCount: 0,
          },
          name: "",
          email: "",
          publicId: "",
          membershipStrength: 0,
        },
      ],
      listRoute: "",
      createRoute: "",
      displayDeleteButton: false,
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      capitalize,
      displayDatatable,
    } = ResusableFunctions();

    onMounted(() => {
      displayDatatable(["basicExample"]);
    });

    return {
      useReusableNavigation,
      reinitialization,
      capitalize,
    };
  },
  created() {
    // Redirect to page after successfully login
    const user = store.getters.currentUser;
    this.displayDeleteButton =
      user.role === variables.SUPER_ADMIN_USER_ROLE ? true : false;

    // The the default rate route
    this.listRoute =
      user.role === variables.SUPER_ADMIN_USER_ROLE
        ? variables.SUPERADMIN_COOPERATIVE_ROUTE
        : variables.BROKER_COOPERATIVE_ROUTE;

    // The the default rate route
    this.createRoute =
      user.role === variables.SUPER_ADMIN_USER_ROLE
        ? variables.SUPERADMIN_CREATE_COOPERATIVE_ROUTE
        : variables.BROKER_CREATE_COOPERATIVE_ROUTE;

    setTimeout(() => {
      // console.log(this.listRoute, this.createRoute);
      this.getCooperativeSocieties();
    }, 1000);
  },
  methods: {
    getCooperativeSocieties() {
      //Fetch list of all cooperative societies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.listRoute)
          .then(({ data }) => {
            //Assign data to form fields
            this.cooperatives = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    deleteCooperative(publicId) {
      // Delete cart item
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.delete(`${this.createRoute}/${publicId}`)
          .then(({ data }) => {
            this.getCooperativeSocieties();
            variables.toastAlert(data.data.message, "success");
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
